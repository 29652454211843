import styles from './Content.module.css';


const Content = () => {
    return (
        <div>
            <div className={styles.container}>
                <h1 className={styles.headline}>Smart code, smarter designs.</h1>
            </div>  
            {/* <div className={styles.container}>
                about
            </div>
            <div className={styles.container}>
                products
            </div>
            <div className={styles.container}>
                contact
            </div> */}
        </div>
        
    )
}

export default Content;