import logo from '../assets/images/logo2.webp';
import styles from './Header.module.css';

const Header = () => {
    return (
        <div className={styles.header}>
            <img src={logo} alt="archiCODE logo" className={styles.logo}/>
        </div>
    )
}

export default Header;