import React from 'react';
import styles from './App.module.css';
import Content from './species/Content';
import Header from './species/Header';
import NavBar from './species/NavBar';

function App() {
  return (
    <div className={styles.App}>
      <Header/>
      <Content/>
      {/* <NavBar/> */}

    </div>
  );
}

export default App;
